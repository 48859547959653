<template>
  <b-modal :id="id" ref="modal" centered size="xl" title="New User" no-close-on-backdrop footer-class="d-block" @hidden="clear()">
    <!-- Content -->
    <template #default>

      <template v-if="state.saving.processing || state.saving.complete">
        <!--Alert Errors -->
        <b-alert v-if="state.errors.length && state.saving.complete" show variant="danger">
          Please copy the following output and send to NERIC.<br/>
          <b-textarea :value="JSON.stringify([user, state])"/>
        </b-alert>

        <!-- Creating User -->
        <b-alert show variant="primary">
          <b-list-group>
            <!-- Cognito User -->
            <b-list-group-item>
              <div class="d-flex justify-content-between align-items-center">
                Create Cognito User
                <b-spinner v-if="state.saving.cognitoUser.saving" small></b-spinner>
                <b-icon v-else :icon="getCreateStatusIcon(state.saving.cognitoUser.variant)" :variant="state.saving.cognitoUser.variant"/>
              </div>
              <div v-if="state.saving.cognitoUser.message" class="font-small-3 font-weight-normal pt-50">{{ state.saving.cognitoUser.message }}</div>
            </b-list-group-item>

						<!-- Cognito User Attribute -->
						<b-list-group-item>
							<div class="d-flex justify-content-between align-items-center">
								Create Cognito User Attributes
								<b-spinner v-if="state.saving.cognitoUserAttributes.saving" small></b-spinner>
								<b-icon v-else :icon="getCreateStatusIcon(state.saving.cognitoUserAttributes.variant)" :variant="state.saving.cognitoUserAttributes.variant"/>
							</div>
							<div v-if="state.saving.cognitoUserAttributes.message" class="font-small-3 font-weight-normal pt-50">{{ state.saving.cognitoUserAttributes.message }}</div>
						</b-list-group-item>

            <!-- Cognito User Groups -->
            <b-list-group-item>
              <div class="d-flex justify-content-between align-items-center">
                Add Cognito User to Cognito Groups
                <b-spinner v-if="state.saving.cognitoUserGroups.saving" small></b-spinner>
                <b-icon v-else :icon="getCreateStatusIcon(state.saving.cognitoUserGroups.variant)" :variant="state.saving.cognitoUserGroups.variant"/>
              </div>

              <b-list-group v-if="state.saving.cognitoUserGroups.items.length" flush class="pt-50">
                <b-list-group-item v-for="(item, index) in state.saving.cognitoUserGroups.items" :key="index" class="pr-0">
                  <div class="d-flex justify-content-between align-items-center">
                    {{ item.title }}
                    <b-spinner v-if="item.saving" small></b-spinner>
                    <b-icon v-else :icon="getCreateStatusIcon(item.variant)" :variant="item.variant"/>
                  </div>
                  <div v-if="item.message" class="font-small-3 font-weight-normal pt-50">{{ item.message }}</div>
                </b-list-group-item>
              </b-list-group>

              <div v-if="state.saving.cognitoUserGroups.message" class="font-small-3 font-weight-normal pt-50">{{ state.saving.cognitoUserGroups.message }}</div>
            </b-list-group-item>

            <!-- AppSync User -->
            <b-list-group-item>
              <div class="d-flex justify-content-between align-items-center">
                Create AppSync User
                <b-spinner v-if="state.saving.appSyncUser.saving" small></b-spinner>
                <b-icon v-else :icon="getCreateStatusIcon(state.saving.appSyncUser.variant)" :variant="state.saving.appSyncUser.variant"/>
              </div>
              <div v-if="state.saving.appSyncUser.message" class="font-small-3 font-weight-normal pt-50">{{ state.saving.appSyncUser.message }}</div>
            </b-list-group-item>

            <!-- AppSync User Schools -->
            <b-list-group-item v-if="user.schools.length">
              <div class="d-flex justify-content-between align-items-center">
                Create AppSync User Schools
                <b-spinner v-if="state.saving.appSyncUserSchools.saving" small></b-spinner>
                <b-icon v-else :icon="getCreateStatusIcon(state.saving.appSyncUserSchools.variant)" :variant="state.saving.appSyncUserSchools.variant"/>
              </div>

              <b-list-group v-if="state.saving.appSyncUserSchools.items.length" flush class="pt-50">
                <b-list-group-item v-for="(item, index) in state.saving.appSyncUserSchools.items" :key="index" class="pr-0">
                  <div class="d-flex justify-content-between align-items-center">
                    {{ item.title }}
                    <b-spinner v-if="item.saving" small></b-spinner>
                    <b-icon v-else :icon="getCreateStatusIcon(item.variant)" :variant="item.variant"/>
                  </div>
                  <div v-if="item.message" class="font-small-3 font-weight-normal pt-50">{{ item.message }}</div>
                </b-list-group-item>
              </b-list-group>

              <div v-if="state.saving.appSyncUserSchools.message" class="font-small-3 font-weight-normal pt-50">{{ state.saving.appSyncUserSchools.message }}</div>
            </b-list-group-item>
          </b-list-group>
        </b-alert>
      </template>
      <template v-else>
        <validation-observer ref="observer" v-slot="{ handleSubmit }" slim>
          <form id="card-container" ref="form" @submit.stop.prevent="handleSubmit(create)">
            <!-- Account Details -->
            <b-card class="border">
              <b-card-title class="mb-75">Account Details</b-card-title>
              <b-card-sub-title class="font-small-3">This information is directly tied to the users login functionality.</b-card-sub-title>
              <b-row class="pt-1">
                <b-col cols="6">
                  <validation-provider v-slot="validationContext" vid="username" name="Username" :rules="rules.username">
                    <b-form-group label="Username" label-for="username-input" :invalid-feedback="validationContext.errors[0]">
                      <b-form-input id="username-input"
                                    v-model="user.username"
                                    :state="getValidationState(validationContext)"/>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col cols="6">
                  <validation-provider v-slot="validationContext" vid="email" name="Email Address" rules="required|email">
                    <b-form-group label="Email Address" label-for="email-input" :invalid-feedback="validationContext.errors[0]">
                      <b-form-input id="email-input"
                                    v-model="user.email"
                                    :state="getValidationState(validationContext)"/>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col cols="12">
                  <validation-provider v-slot="validationContext" vid="group" name="Group" rules="required">
                    <b-form-group label="Group" label-for="group-input" :invalid-feedback="validationContext.errors[0]" :state="getValidationState(validationContext)">
                      <v-select id="group-input"
                                v-model="user.groups" multiple
                                label="GroupName"
                                :options="groups"
                                :select-on-tab="true"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                class="w-100"/>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>
            </b-card>

            <!-- Personal Details -->
            <b-card class="border">
              <b-card-title class="mb-75">Personal Details</b-card-title>
              <b-card-sub-title class="font-small-3">This information is used to identify the user, as well as contact them if need be.</b-card-sub-title>
              <b-row class="pt-1">
                <b-col cols="6">
                  <validation-provider v-slot="validationContext" vid="first-name" name="First Name" rules="required">
                    <b-form-group label="First Name" label-for="first-name-input" :invalid-feedback="validationContext.errors[0]">
                      <b-form-input id="first-name-input"
                                    v-model="user.name.first"
                                    :state="getValidationState(validationContext)"/>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col cols="6">
                  <validation-provider v-slot="validationContext" vid="last-name" name="Last Name" rules="required">
                    <b-form-group label="Last Name" label-for="last-name-input" :invalid-feedback="validationContext.errors[0]">
                      <b-form-input id="last-name-input"
                                    v-model="user.name.last"
                                    :state="getValidationState(validationContext)" />
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col cols="12">
                  <validation-provider v-slot="validationContext" vid="phone-number" name="Phone Number" rules="required">
                    <b-form-group label="Phone Number" label-for="phone-input" :invalid-feedback="validationContext.errors[0]">
                      <b-form-input id="phone-input"
                                    v-model="user.phone"
                                    v-mask="'+1 (###) ###-####'"
                                    placeholder="(###) ###-####"
                                    :state="getValidationState(validationContext)" />
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>

              <!-- Address -->
              <template v-if="isTeacher() || isChair()">
                <section class="mt-1">
                  <b-card-sub-title class="font-small-3">Home Address</b-card-sub-title>
                  <b-row class="pt-1">
                    <b-col>
                      <validation-provider v-slot="validationContext" vid="line1" name="Line 1" rules="required">
                        <b-form-group label="Line 1" label-for="line1-input" :invalid-feedback="validationContext.errors[0]">
                          <b-form-input id="line1-input"
                                        v-model="user.address.line1"
                                        :state="getValidationState(validationContext)"></b-form-input>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <b-col>
                      <validation-provider v-slot="validationContext" vid="line2" name="Line 2" rules="">
                        <b-form-group label="Line 2" label-for="line2-input" :invalid-feedback="validationContext.errors[0]">
                          <b-form-input id="line2-input"
                                        v-model="user.address.line2"
                                        :state="getValidationState(validationContext)"/>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <validation-provider v-slot="validationContext" vid="city" name="City" :rules="rules.city">
                        <b-form-group label="City" label-for="city-input" :invalid-feedback="validationContext.errors[0]">
                          <b-form-input id="city-input"
                                        v-model="user.address.city"
                                        :state="getValidationState(validationContext)"/>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <b-col>
                      <validation-provider v-slot="validationContext" vid="county" name="County" rules="required">
                        <b-form-group label="County" label-for="county-input" :invalid-feedback="validationContext.errors[0]" :state="getValidationState(validationContext)">
                          <v-select id="county-input"
                                    v-model="user.address.county"
                                    :options="counties"
                                    :loading="options.counties.loading"
                                    :select-on-tab="true"
                                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                    class="w-100"/>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <b-col cols="auto">
                      <validation-provider v-slot="validationContext" vid="state" name="State" :rules="rules.state">
                        <b-form-group label="State" label-for="state-input" :invalid-feedback="validationContext.errors[0]">
                          <state-input id="state-input"
                                       v-model="user.address.state"
                                       :validation-state="getValidationState(validationContext)"/>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <b-col cols="auto">
                      <validation-provider v-slot="validationContext" vid="zip-code" name="Zip Code" :rules="rules.zip">
                        <b-form-group label="Zip Code" label-for="zip-code-input" :invalid-feedback="validationContext.errors[0]">
                          <zip-input id="zip-code-input"
                                     v-model="user.address.zip"
                                     :validation-state="getValidationState(validationContext)"/>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                  </b-row>
                </section>
              </template>
            </b-card>

            <!-- Group Permissions -->
            <b-card v-if="isChair() || isCommittee() || isTeacher() || isZoneRep()" class="border">
              <b-card-title class="mb-75">Group Permissions</b-card-title>
              <b-card-sub-title class="font-small-3">The following settings are specific to the {{ user.group }} group and will be used to provision access.</b-card-sub-title>
              <template v-if="isChair()">
                <b-row class="pt-1">
                  <b-col>
                    <validation-provider v-slot="validationContext" vid="ensembles" name="Ensembles" rules="required">
                      <b-form-group label="Ensembles" label-for="school-input" :invalid-feedback="validationContext.errors[0]" :state="getValidationState(validationContext)">
                        <v-select id="school-input"
                                  v-model="user.ensembles" multiple
                                  label="name"
                                  :options="ensembles"
                                  :selectable="option => option.state.enabled === true"
                                  :reduce="val => val"
                                  :select-on-tab="true"
                                  :loading="options.ensembles.loading"
                                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                  class="w-100">

                          <template #option="{ name, state }">
                            <div class="d-flex justify-content-between align-items-center">
                              <div>
                                {{ name }}
                              </div>
                              <span v-if="!state.enabled" title="Disabled"><b-icon-lock-fill /></span>
                            </div>
                          </template>
                        </v-select>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                </b-row>
              </template>
							<template v-else-if="isCommittee()">
								<b-row class="pt-1">
									<b-col>
										<validation-provider v-slot="validationContext" vid="instruments" name="Instruments" rules="required">
											<b-form-group label="Instruments" label-for="school-input" :invalid-feedback="validationContext.errors[0]" :state="getValidationState(validationContext)">
												<v-select id="school-input"
																	v-model="user.instruments" multiple
																	label="name"
																	:options="instruments"
																	:selectable="option => option.state.enabled === true"
																	:reduce="val => val"
																	:select-on-tab="true"
																	:loading="options.instruments.loading"
																	:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
																	class="w-100">

													<template #option="{ name, state }">
														<div class="d-flex justify-content-between align-items-center">
															<div>
																{{ name }}
															</div>
															<span v-if="!state.enabled" title="Disabled"><b-icon-lock-fill /></span>
														</div>
													</template>
												</v-select>
											</b-form-group>
										</validation-provider>
									</b-col>
								</b-row>
							</template>
              <template v-else-if="isTeacher()">
                <b-row class="pt-1">
                  <b-col cols="auto" align-self="start">
                    <b-form-group label="Type" label-for="phone-input">
                      <b-checkbox v-model="options.isPublic"
                                  button button-variant="outline-primary"
                                  @change="clearDistrictAndSchoolSelection">
                        {{ options.isPublic ? 'Public' : 'Non-Public' }}
                      </b-checkbox>
                    </b-form-group>
                  </b-col>
                  <b-col v-if="options.isPublic" align-self="start">
                    <validation-provider v-slot="validationContext" vid="district" name="District" rules="required">
                      <b-form-group label="District" label-for="district-input" :invalid-feedback="validationContext.errors[0]" :state="getValidationState(validationContext)">
                        <v-select id="district-input"
                                  v-model="user.district"
                                  label="name"
                                  :options="districts"
                                  :selectable="option => option.state.enabled === true"
                                  :reduce="val => val"
                                  :filter="districtSearch"
                                  :select-on-tab="true"
                                  :loading="options.districts.loading"
                                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                  class="w-100"
                                  @option:selected="clearSchoolSelection">

                          <template #option="{ name, state }">
                            <div class="d-flex justify-content-between align-items-center">
                              <div>
                                <h6 class="mb-0">{{ name.legal }}</h6>
                                <small>{{ name.popular }}</small>
                              </div>
                              <span v-if="!state.enabled" title="Disabled"><b-icon-lock-fill /></span>
                            </div>
                          </template>

                          <template #selected-option="{ name }">
                            {{ name.legal }}
                          </template>
                        </v-select>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col align-self="start">
                    <validation-provider v-slot="validationContext" vid="school" name="School" rules="required">
                      <b-form-group label="School" label-for="school-input" :invalid-feedback="validationContext.errors[0]" :state="getValidationState(validationContext)">
                        <v-select id="school-input"
                                  v-model="user.schools" multiple
                                  label="name"
                                  :options="schools"
                                  :selectable="option => option.state.enabled === true"
                                  :reduce="val => val"
                                  :filter="schoolSearch"
                                  :select-on-tab="true"
                                  :loading="options.schools.loading"
                                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                  class="w-100">

                          <template #option="{ name, state }">
                            <div class="d-flex justify-content-between align-items-center">
                              <div>
                                <h6 class="mb-0">{{ name.legal }}</h6>
                                <small>{{ name.popular }}</small>
                              </div>
                              <span v-if="!state.enabled" title="Disabled"><b-icon-lock-fill /></span>
                            </div>
                          </template>

                          <template #selected-option="{ name }">
                            {{ name.legal }}
                          </template>
                        </v-select>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                </b-row>
              </template>
              <template v-if="isZoneRep()">
                <b-row class="pt-1">
                  <b-col>
                    <validation-provider v-slot="validationContext" vid="zones" name="Zones" rules="required">
                      <b-form-group label="Zones" label-for="zone-input" :invalid-feedback="validationContext.errors[0]" :state="getValidationState(validationContext)">
                        <v-select id="zone-input"
                                  v-model="user.zones" multiple
                                  label="name"
                                  :options="zones"
                                  :selectable="option => option.state.enabled === true"
                                  :reduce="val => val"
                                  :select-on-tab="true"
                                  :loading="options.zones.loading"
                                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                  class="w-100">

                          <template #option="{ name, state }">
                            <div class="d-flex justify-content-between align-items-center">
                              <div>
                                {{ name }}
                              </div>
                              <span v-if="!state.enabled" title="Disabled"><b-icon-lock-fill /></span>
                            </div>
                          </template>
                        </v-select>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                </b-row>
              </template>

            </b-card>
          </form>
        </validation-observer>
      </template>
    </template>
    <!-- Footer -->
    <template #modal-footer>
      <b-row>
        <b-col align-self="center" class="pl-0">
          <b-checkbox v-model="state.redirect" :checked="true" :unchecked-value="false" :disabled="state.saving.processing || state.saving.complete">
            <small>Redirect to page on submit</small>
          </b-checkbox>
        </b-col>
        <b-col align-self="center" class="text-right pr-0">
          <b-button type="reset" class="mr-1" :disabled="state.saving.processing" @click="clear()">Clear</b-button>
          <b-button type="submit" variant="primary" :disabled="state.saving.processing || state.saving.complete" @click="$refs.observer.handleSubmit(create)">Submit</b-button>
        </b-col>
      </b-row>
    </template>
  </b-modal>
</template>

<script>
import { mask } from 'vue-the-mask'
import vSelect from 'vue-select'
import notify from '@/mixins/notify.mixin'
import Avatar from '@/models/avatar';
import {
    listDistricts, listSchools, listEnsembles, listZones,
    createUser, createUserSchools, deleteUser,
    onUpdateDistrict, onUpdateSchool, onUpdateEnsemble, listInstruments
} from '@/graphql/queries/user-modal';
import { API, graphqlOperation } from 'aws-amplify';
import {adminCreateUser, adminAddUserToGroup, adminUpdateUserAttributes} from '@/scripts/aws';
import Fuse from 'fuse.js';
import {rules} from '@/data/validation-rules';
import StateInput from '@/components/StateInput.vue';
import ZipInput from '@/components/ZipInput.vue';
import { uuid } from 'vue-uuid';

export default {
  name: 'UserModal',
  directives: { mask },
  components: {
    ZipInput,
    StateInput,
    vSelect
  },
  mixins: [ notify ],
  props: {
    id: {
      type: String,
      required: true,
    },
    groups: {
      type: Array,
      required: true
    },
    usernames: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      user: {
        name: {
          first: null,
          last: null
        },
        username: null,
        email: null,
        groups: [],
        district: null,
        schools: [],
        ensembles: [],
        instruments: [],
        zones: [],
        phone: null,
        address: {
          line1: null,
          line2: null,
          city: null,
          county: null,
          state: null,
          zip: null
        }
      },
      options: {
        isPublic: true,
        districts: {
          items: [],
          loading: false,
          subscription: null
        },
        schools: {
          items: [],
          loading: false,
          subscription: null
        },
        ensembles: {
          items: [],
          loading: true,
          subscription: null
        },
        instruments: {
            items: [],
            loading: true,
            subscription: null
        },
        zones: {
          items: [],
          loading: true,
          subscription: null
        },
        counties: {
          loaded: false,
          loading: false,
          items: []
        }
      },
      rules: {
        username: {
          required: true,
          alpha_dash: true,
          min: 4,
          max: 24,
          excluded: this.usernames
        },
        city: rules.address.city,
        state: rules.address.state,
        zip: rules.address.zip
      },
      state: {
        loading: false,
        errors: [],
        redirect: false,
        saving: {
          complete: false,
          processing: false,
          cognitoUser: { saving: false, variant: 'primary', message: null },
          cognitoUserAttributes: { saving: false, variant: 'primary', message: null },
          cognitoUserGroups: { saving: false, variant: 'primary', message: null, items: [] },
          appSyncUser: { saving: false, variant: 'primary', message: null },
          appSyncUserSchools: { saving: false, variant: 'primary', message: null, items: [] }
        }
      },
    }
  },
  computed: {
    phoneNumber() {
      return this.user.phone ? this.user.phone.replace(/[- )(]/g, '') : null
    },
    districts() {
      if(this.options.districts.items.length === 0 ) {
        this.listDistricts()
        this.onUpdateDistrict()
        return []
      }
      return this.options.districts.items
    },
    schools() {
      if(this.options.isPublic) {
        return this.user.district ? this.options.districts.items.find(district => district.id === this.user.district.id).schools.items : []
      }
      if(this.options.schools.items.length === 0 ) {
        this.listSchools()
        this.onUpdateSchool()
        return []
      }
      return this.options.schools.items
    },
    ensembles() {
      if(this.options.ensembles.loading) {
        this.listEnsembles()
        this.onUpdateEnsemble()
        return []
      }
      return this.options.ensembles.items
    },
    instruments() {
        if(this.options.instruments.loading) {
            this.listInstruments()
            return []
        }
        return this.options.instruments.items
    },
    zones() {
      if(this.options.zones.loading) {
        this.listZones()
        return []
      }
      return this.options.zones.items
    },
    counties() {
      if(!this.options.counties.loaded && this.isTeacher()) {
        this.listZones()
        return []
      }
      return this.options.counties.items
    }
  },
  watch: {
    usernames(value) {
      this.rules.username.excluded = value
    }
  },
  beforeDestroy() {
    if(this.options?.districts?.subscription) { this.options.districts.subscription.unsubscribe() }
    if(this.options?.schools?.subscription) { this.options.schools.subscription.unsubscribe() }
    if(this.options?.ensembles?.subscription) { this.options.ensembles.subscription.unsubscribe() }
  },
  methods: {
    async listDistricts(nextToken, pagedDistricts) {
      this.options.districts.loading = true
      this.options.schools.loading = true

      const districts = pagedDistricts || []
      const response = await API.graphql(graphqlOperation(listDistricts, { limit: 500, nextToken: nextToken }));

      districts.push(...response.data.listDistricts.items)

      if(response.data.listDistricts.nextToken) {
        await this.listDistricts(response.data.listDistricts.nextToken, districts)
      }
      else {
        this.options.districts.items = districts.sort((a, b) => a.name.legal.localeCompare(b.name.legal));
        this.options.districts.loading = false
        this.options.schools.loading = false
      }
    },
    async listSchools(nextToken, pagedSchools) {
      this.options.schools.loading = true

      const schools = pagedSchools || []
      const response = await API.graphql(graphqlOperation(listSchools, { limit: 500, nextToken: nextToken, filter: { districtSchoolsId: { attributeExists: false }} }));

      schools.push(...response.data.listSchools.items)

      if(response.data.listSchools.nextToken) {
        await this.listSchools(response.data.listSchools.nextToken, schools)
      }
      else {
        this.options.schools.items = schools.sort((a, b) => a.name.legal.localeCompare(b.name.legal));
        this.options.schools.loading = false
      }
    },
    async listEnsembles(nextToken, pagedEnsembles) {
      const ensembles = pagedEnsembles || []
      const response = await API.graphql(graphqlOperation(listEnsembles, { limit: 100, nextToken: nextToken }));

      ensembles.push(...response.data.listEnsembles.items)

      if(response.data.listEnsembles.nextToken) {
        await this.listEnsembles(response.data.listEnsembles.nextToken, ensembles)
      }
      else {
        this.options.ensembles.items = ensembles.sort((a, b) => a.name.localeCompare(b.name));
        this.options.ensembles.loading = false
      }
    },
      async listInstruments(nextToken, pagedItems) {
          const items = pagedItems || []
          const response = await API.graphql(graphqlOperation(listInstruments, { limit: 100, nextToken: nextToken }));

          items.push(...response.data.listInstruments.items)

          if(response.data.listInstruments.nextToken) {
              await this.listInstruments(response.data.listInstruments.nextToken, items)
          }
          else {
              this.options.instruments.items = items.sort((a, b) => a.name.localeCompare(b.name));
              this.options.instruments.loading = false
          }
      },
    async listZones(nextToken, pagedItems) {
      this.options.counties.loading = true

      const items = pagedItems || []
      const response = await API.graphql(graphqlOperation(listZones, { limit: 500, nextToken: nextToken }));

      items.push(...response.data.listZones.items)

      if(response.data.listZones.nextToken) {
        await this.listZones(response.data.listZones.nextToken, items)
      }
      else {
        this.options.zones.items = items.sort((a, b) => a.name.localeCompare(b.name, 'en', { numeric: true }))
        this.options.zones.loading = false

        const counties = []
        items.forEach(item => { counties.push(...item.counties) })

        this.options.counties.items = counties.sort((a, b) => a.localeCompare(b));
        this.options.counties.loaded = true
        this.options.counties.loading = false
      }
    },

    async onUpdateDistrict() {
      this.options.districts.subscription = API.graphql(graphqlOperation(onUpdateDistrict)).subscribe((sourceData) => {
        this.syncNotification()
        const district = sourceData.value.data.onUpdateDistrict
        if (district && this.options.districts.items.map(item => item.id).includes(district.id)) {
          this.options.districts.loading = true
          const index = this.options.districts.items.findIndex(item => item.id === district.id)
          if(index > -1) {
            this.options.districts.items.splice(index, 1, district)
          }
          this.options.districts.loading = false
        }
      });
    },
    async onUpdateSchool() {
      this.options.schools.subscription = API.graphql(graphqlOperation(onUpdateSchool)).subscribe((sourceData) => {
        this.syncNotification()
        const school = sourceData.value.data.onUpdateSchool
        if (school && this.options.schools.items.map(item => item.id).includes(school.id)) {
          this.options.schools.loading = true
          const index = this.options.schools.items.findIndex(item => item.id === school.id)
          if(index > -1) {
            this.options.schools.items.splice(index, 1, school)
          }
          this.options.schools.loading = false
        }
      });
    },
    async onUpdateEnsemble() {
      this.options.ensembles.subscription = API.graphql(graphqlOperation(onUpdateEnsemble)).subscribe((sourceData) => {
        this.syncNotification()
        const ensemble = sourceData.value.data.onUpdateEnsemble
        if (ensemble && this.options.ensembles.items.map(item => item.id).includes(ensemble.id)) {
          this.options.ensembles.loading = true
          const index = this.options.ensembles.items.findIndex(item => item.id === ensemble.id)
          if(index > -1) {
            this.options.ensembles.items.splice(index, 1, ensemble)
          }
          this.options.ensembles.loading = false
        }
      });
    },

    async create() {
      this.state.saving.complete = false;
      this.state.saving.processing = true;

      try {
        this.state.saving.cognitoUser.saving = true
        const adminCreateUserResponse = await adminCreateUser({ username: this.user.username, email: this.user.email, phone_number: this.phoneNumber })
        if(adminCreateUserResponse?.User) {
          this.state.saving.cognitoUser.saving = false
          this.state.saving.cognitoUser.variant = 'success'

          const username = adminCreateUserResponse.User.Username
          const subAttribute = adminCreateUserResponse.User.Attributes.find(attribute => attribute.Name === 'sub');

          /** Add Cognito User Attribute **/
          try {
              if (subAttribute) {
                  const attributes = [
                      {
                          Name: 'custom:user_id',
                          Value: subAttribute.Value,
                      },
                  ];
                  this.state.saving.cognitoUserAttributes.saving = true
                  await adminUpdateUserAttributes(username, attributes);
                  this.state.saving.cognitoUserAttributes.saving = false
                  this.state.saving.cognitoUserAttributes.variant = 'success'
              }
          }
          catch (e12) {
            //failed to add cognito user attribute
            this.state.errors.push(e12)
            this.state.saving.cognitoUserAttributes.variant = 'danger'
            this.state.saving.cognitoUserAttributes.message = e12
          }

          /** Add Cognito User to Group **/
          try {
            this.state.saving.cognitoUserGroups.saving = true
            await this.user.groups.reduce(async (referencePoint, group, index) => {
              try {
                await referencePoint;
                this.state.saving.cognitoUserGroups.items.push({ saving: true, variant: 'primary', title: group, message: null})

                await adminAddUserToGroup(username, group);
                this.state.saving.cognitoUserGroups.items.splice(index, 1, { saving: false, variant: 'success', title: group, message: null })
              }
              catch (e121) {
                //failed to add cognito user to cognito group
                this.state.errors.push(e121)
                this.state.saving.cognitoUserGroups.items.splice(index, 1, { saving: false, variant: 'danger', message: e121 })
              }
            }, Promise.resolve());

            this.state.saving.cognitoUserGroups.saving = false
            if(this.state.saving.cognitoUserGroups.items.every(item => item.variant === 'success')) { this.state.saving.cognitoUserGroups.variant = 'success' }
            else if(this.state.saving.cognitoUserGroups.items.every(item => item.variant === 'danger')) { this.state.saving.cognitoUserGroups.variant = 'danger' }
            else { this.state.saving.cognitoUserGroups.variant = 'warning' }
          }
          catch(e13) {
            //failed to add user to group
            this.state.errors.push(e13)
            this.state.saving.cognitoUserGroups = { saving: false, variant: 'danger', message: e13 }
          }

          /** Create AppSync User **/
          try {
            this.state.saving.appSyncUser.saving = true
            const createUserInput = {
              id: subAttribute.Value,
              username: username,
              name: this.user.name,
              address: this.user.address,
              avatar: new Avatar(),
              ensembleIds: this.user.ensembles.map(ensemble => ensemble.id),
              instrumentIds: this.user.instruments.map(ensemble => ensemble.id),
              zoneIds: this.user.zones.map(zone => zone.id)
            }
            const createUserResponse = await API.graphql({query: createUser, variables: { input: createUserInput }})
            if(createUserResponse) {
              this.state.saving.appSyncUser.saving = false
              this.state.saving.appSyncUser.variant = 'success'

              /** Create Amplify User Schools **/
              if(this.user?.schools?.length) {
                try {
                  this.state.saving.appSyncUserSchools.saving = true
                  await this.user.schools.reduce(async (referencePoint, school, index) => {
                    try {
                      await referencePoint;
                      this.state.saving.appSyncUserSchools.items.push({ saving: true, variant: 'primary', message: null})

                      await API.graphql(graphqlOperation(createUserSchools, { input: { id: uuid.v4(), userID: createUserInput.id, schoolID: school.id } }));
                      this.state.saving.appSyncUserSchools.items.splice(index, 1, { saving: false, variant: 'success', title: school?.name?.legal, message: null})
                    }
                    catch (e132) {
                      //failed to create appsync user school
                      this.state.errors.push(e132)
                      this.state.saving.appSyncUserSchools.items.splice(index, 1, { saving: false, variant: 'danger', title: school?.name?.legal, message: e132 })
                    }
                  }, Promise.resolve());

                  this.state.saving.appSyncUserSchools.saving = false
                  if(this.state.saving.appSyncUserSchools.items.every(item => item.variant === 'success')) { this.state.saving.appSyncUserSchools.variant = 'success' }
                  else if(this.state.saving.appSyncUserSchools.items.every(item => item.variant === 'danger')) { this.state.saving.appSyncUserSchools.variant = 'danger' }
                  else { this.state.saving.appSyncUserSchools.variant = 'warning' }
                }
                catch(e131) {
                  //failed to create appsync user schools
                  this.state.errors.push(e131)
                  this.state.saving.appSyncUserSchools.saving = false
                  this.state.saving.appSyncUserSchools.variant = 'danger'
                  this.state.saving.appSyncUserSchools.message = e131
                }
              }

              /** Return AppSync User **/
              const user = createUserResponse.data.createUser
              this.$emit('created', user)
            }
          }
          catch(e14) {
            //failed to create amplify user
            this.state.errors.push(e14)
            this.state.saving.appSyncUser = { saving: false, variant: 'danger', message: e14 }
            this.state.saving.appSyncUserSchools = { saving: false, variant: 'warning', message: 'Skipped due to failure of Create AppSync User' }
          }
        }
      }
      catch(e1) {
        //user already exists?
        this.state.errors.push(e1)
        this.state.saving.cognitoUser = { saving: false, variant: 'danger', message: e1 }
        this.state.saving.cognitoUserGroups = { saving: false, variant: 'warning', message: 'Skipped due to failure of Create Cognito User', items: [] }
        this.state.saving.appSyncUser = { saving: false, variant: 'warning', message: 'Skipped due to failure of Create Cognito User' }
        this.state.saving.appSyncUserSchools = { saving: false, variant: 'warning', message: 'Skipped due to failure of Create Cognito User', items: [] }
      }
      finally {
        this.state.saving.complete = true
        this.state.saving.processing = false;
      }
    },
    getCreateStatusIcon(variant) {
      if(variant === 'success') return 'check-circle-fill'
      if(variant === 'warning') return 'slash-circle-fill'
      if(variant === 'danger') return 'x-circle-fill'
      return 'circle'
    },

    clear() {
      this.user = {
        name: {
          first: null,
          last: null
        },
        username: null,
        email: null,
        groups: [],
        district: null,
        schools: [],
        ensembles: [],
        instruments: [],
        zones: [],
        phone: null,
        address: {
          line1: null,
          line2: null,
          city: null,
          county: null,
          state: null,
          zip: null
        }
      }
      this.state.errors = []
      this.state.saving = {
        complete: false,
        processing: false,
        cognitoUser: { saving: false, variant: 'primary', message: null },
        cognitoUserAttributes: { saving: false, variant: 'primary', message: null },
        cognitoUserGroups: { saving: false, variant: 'primary', message: null, items: [] },
        appSyncUser: { saving: false, variant: 'primary', message: null },
        appSyncUserSchools: { saving: false, variant: 'primary', message: null, items: [] }
      }
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    districtSearch(options, search) {
      const fuse = new Fuse(options, {
        keys: ['name.legal', 'name.popular'],
        shouldSort: true,
        threshold: 0.2
      })
      return search.length ? fuse.search(search).map(({ item }) => item) : fuse.list
    },
    schoolSearch(options, search) {
      const fuse = new Fuse(options, {
        keys: ['name.legal', 'name.popular'],
        shouldSort: true,
        threshold: 0.2
      })
      return search.length ? fuse.search(search).map(({ item }) => item) : fuse.list
    },
    clearSchoolSelection() {
      this.user.school = null
    },
    clearDistrictAndSchoolSelection() {
      this.user.district = null
      this.user.school = null
    },

    /** Group Utils **/
    isChair() {
      return this.user?.groups?.includes('NYSSMA_Chair') || false
    },
    isCommittee() {
        return this.user?.groups?.includes('NYSSMA_Committee') || false
    },
    isTeacher() {
      return this.user?.groups?.includes('Teacher') || false
    },
    isZoneRep() {
      return this.user?.groups?.includes('ZoneRep') || false
    },
  }
}
</script>

<style scoped>
  #card-container > :last-child {
    margin-bottom: 0;
  }
</style>
