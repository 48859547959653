/** Table Data **/
export const listApplications = /* GraphQL */ `
    query ListApplications(
        $id: ID
        $filter: ModelApplicationFilterInput
        $limit: Int
        $nextToken: String
        $sortDirection: ModelSortDirection
        $includeTeacher: Boolean = false
    ) {
        listApplications(
            id: $id
            filter: $filter
            limit: $limit
            nextToken: $nextToken
            sortDirection: $sortDirection
        ) {
            items {
                id
                applicationFestivalId
                applicationFormId
                applicationInstrumentId
                teacherID
                studentApplicationsId
                teacher @include(if: $includeTeacher) {
                    id
                    name {
                        first
                        last
                    }
                }
                createdAt
                updatedAt
            }
            nextToken
        }
    }
`;
export const listApplicationsByTeacher = /* GraphQL */ `
    query ListApplicationsByTeacher(
        $teacherID: ID!
        $sortDirection: ModelSortDirection
        $filter: ModelApplicationFilterInput
        $limit: Int
        $nextToken: String
    ) {
        listApplicationsByTeacher(
            teacherID: $teacherID
            sortDirection: $sortDirection
            filter: $filter
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                id
                applicationFestivalId
                applicationFormId
                applicationInstrumentId
                studentApplicationsId
                teacher {
                    id
                    name {
                        first
                        last
                    }
                }
                createdAt
                updatedAt
            }
            nextToken
        }
    }
`;



export const listStudents = /* GraphQL */ `
    query ListStudents(
        $id: ID
        $filter: ModelStudentFilterInput
        $limit: Int
        $nextToken: String
        $sortDirection: ModelSortDirection
    ) {
        listStudents(
            id: $id
            filter: $filter
            limit: $limit
            nextToken: $nextToken
            sortDirection: $sortDirection
        ) {
            items {
                id
                name {
                    first
                    last
                }
                schoolID
            }
            nextToken
        }
    }
`;



/** Table Filters **/
export const listFestivals = /* GraphQL */ `
    query ListFestivals(
        $id: ID
        $filter: ModelFestivalFilterInput
        $limit: Int
        $nextToken: String
        $sortDirection: ModelSortDirection
    ) {
        listFestivals(
            id: $id
            filter: $filter
            limit: $limit
            nextToken: $nextToken
            sortDirection: $sortDirection
        ) {
            items {
                id
                name
                site
            }
            nextToken
        }
    }
`;
export const listForms = /* GraphQL */ `
    query ListForms(
        $id: ID
        $filter: ModelFormFilterInput
        $limit: Int
        $nextToken: String
        $sortDirection: ModelSortDirection
    ) {
        listForms(
            id: $id
            filter: $filter
            limit: $limit
            nextToken: $nextToken
            sortDirection: $sortDirection
        ) {
            items {
                id
                name
                instruments {
                    items {
                        id
                        name
                    }
                }
            }
            nextToken
        }
    }
`;
export const listSchools = /* GraphQL */ `
    query ListSchools(
        $id: ID
        $filter: ModelSchoolFilterInput
        $limit: Int
        $nextToken: String
        $sortDirection: ModelSortDirection
    ) {
        listSchools(
            id: $id
            filter: $filter
            limit: $limit
            nextToken: $nextToken
            sortDirection: $sortDirection
        ) {
            items {
                id
                name {
                    legal
                }
            }
            nextToken
        }
    }
`;

/** Deletion **/
export const getApplication = /* GraphQL */ `
    query GetApplication($id: ID!) {
        getApplication(id: $id) {
            id
            festival {
                id
                name
            }
            form {
                id
                name
            }
            instrument {
                id
                name
            }
            student {
                id
                name {
                    first
                    last
                }
                school {
                    name {
                        legal
                    }
                }
            }
            teacher {
                id
                name {
                    first
                    last
                }
            }
            createdAt
            updatedAt
        }
    }
`;

/** Subscriptions **/
export const onCreateApplication = /* GraphQL */ `
    subscription OnCreateApplication {
        onCreateApplication {
            id
        }
    }
`;
export const onDeleteApplication = /* GraphQL */ `
    subscription OnDeleteApplication {
        onDeleteApplication {
            id
        }
    }
`;
