<template>
  <page-layout ref="layout">

    <template #actions="{ }">
      <b-button v-b-tooltip="'Create'" v-b-modal="'user-modal'" variant="transparent" size="sm">
        <font-awesome-icon icon="fa-solid fa-plus" />
      </b-button>
      <user-modal v-if="!options.groups.loading"
                  id="user-modal"
                  :groups="options.groups.items"
                  :usernames="usernames"
                  @created="addUser" />
    </template>

    <template #dropdown-options="">
      <b-dropdown-item @click="refresh">
        <font-awesome-icon icon="fa-solid fa-rotate-right"></font-awesome-icon>
        <span class="align-middle ml-50">Refresh</span>
      </b-dropdown-item>
    </template>

    <template #content="{ state }">
        <table-layout ref="table-layout"
                      :items="table.items"
                      :loading="table.loading"
                      :fields="table.fields"
                      :filters="table.filters" :filters-options="{ visible: true, collapsed: true }"
                      :sorting="table.sorting"
                      :subscriptions="table.subscriptions"
                      :func-delete="deleteUser"
                      :func-refresh="refresh"
                      :export-exclude-fields="[
                        'id',
                        'avatar.size',
                        'avatar.src',
                        'avatar.name',
                        'state.enabled',
                        'state.status',
                        'state.verified',
                        'state.createdAt',
                      ]"
                      @mounted="table = $event"
                      @updated="table = $event">



          <template #overlay>
            <overlay-loading :items="[
/*            { state: options.cognitoUsers.loading, desc: 'Loading Users', loaded: options.cognitoUsers.items.length },
            { state: options.amplifyUsers.loading, desc: 'Loading User Details', loaded: options.amplifyUsers.items.length },
            { state: options.cognitoGroups.loading, desc: 'Loading Groups', loaded: options.cognitoGroups.items.length },*/
            { state: table.loading, desc: 'Loading Users', loaded: table.loaded },
            { state: state.loading, desc: 'Rendering Template'},
          ]" />
          </template>

          <!-- Filters -->
          <template #filters>
            <b-row>
              <b-col>
                <b-form-group label="Group" label-for="district-input">
                  <v-select id="district-input"
                            v-model="table.filters.groups.value"
                            :options="options.groups.items"
                            :loading="options.groups.loading"
                            :select-on-tab="true"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            class="w-100"/>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group label="Enabled" label-for="enabled-input">
                  <v-select id="enabled-input"
                            v-model="table.filters.enabled.value"
                            :options="['true', 'false']"
                            :searchable="false"
                            :select-on-tab="true"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            class="w-100"/>
                </b-form-group>
              </b-col>
            </b-row>
          </template>

          <!-- Column: Name -->
          <template #cell(name)="{data}">
            <b-media vertical-align="center" no-body>
              <b-media-aside>
                <b-avatar v-if="hasCustomAvatar(data.item.avatar)" v-b-modal.avatar-modal variant="primary" size="3.25em" badge-variant="white">
                  <b-img fluid fluid-grow :src="data.item.avatar.src" :alt="data.item.avatar.name"></b-img>
                </b-avatar>
                <b-avatar v-else v-b-modal.avatar-modal variant="primary" size="3.25em" badge-variant="white">
                  <font-awesome-icon :icon="icon" size="lg"></font-awesome-icon>
                </b-avatar>
              </b-media-aside>
              <b-media-body class="align-self-center">
                <b-link :to="{ name: 'management-user', params: { id: data.item.id, username: data.item.username } }" class="font-weight-bold d-block text-nowrap">
                  <template v-if="data.item.name">
                    {{ data.item.name.first }} {{ data.item.name.last }}<br/>
                  </template>
                  <small>@{{ data.item.username }}</small>
                </b-link>
              </b-media-body>
            </b-media>
          </template>


          <!-- Column: Schools -->
          <template #cell(schools.items)="{data}">
            <template v-if="data.item.schools.items.length > 0">
              <b-badge v-for="item in data.item.schools.items" :key="item.id" variant="primary" class="mr-1">
                {{ item.school.name.legal }}
              </b-badge>
            </template>
            <template v-else-if="data.item.groups.includes('Teacher') && data.item.schools.items.length === 0">
              <b-badge class="mr-1" variant="danger">
                Missing School(s)
              </b-badge>
            </template>
            <template v-else>
              &nbsp;
            </template>
          </template>

          <!-- Column: Group -->
<!--
          <template #cell(group)="{data}">
            <b-badge pill :variant="groupVariant(data.item.group)" class="group-badge text-capitalize">
              {{ data.item.group }}
            </b-badge>
          </template>
-->

          <template #cell(groups)="{data}">
            <b-badge v-for="group in data.item.groups" :key="group" pill :variant="groupVariant(group)" class="group-badge text-capitalize">
              {{ group }}
            </b-badge>
          </template>

          <!-- Column: State - Enabled -->
          <template #cell(state.enabled)="{data}">
            <b-badge pill :variant="`light-${data.item.state.enabled ? 'success' : 'danger'}`" class="text-capitalize">
              {{ data.item.state.enabled }}
            </b-badge>
          </template>

          <!-- Column: State - Status -->
          <template #cell(state.status)="{data}">
            <b-badge pill :variant="statusVariant(data.item.state.status)" class="group-badge text-capitalize">
              {{ data.item.state.status }}
            </b-badge>
          </template>

          <!-- Column: Actions -->
          <template #cell(row-options)="{data}">
            <b-dropdown-item :to="{ name: 'management-user', params: { id: data.item.id, username: data.item.username } }"
                             class="table-row-option-view">
              <feather-icon icon="FileTextIcon" />
              <span class="align-middle ml-50">View</span>
            </b-dropdown-item>
            <template v-if="data.item.state.status === 'CONFIRMED'
                            || data.item.state.status === 'FORCE_CHANGE_PASSWORD'
                            || (!data.item.state.verified && data.item.state.status === 'CONFIRMED')
                            || data.item.state.status === 'RESET_REQUIRED'">
              <b-dropdown-divider/>
              <b-dropdown-item v-if="data.item.state.status === 'CONFIRMED'"
                               class="table-row-option-reset-password"
                               @click="resetUserPassword(data.item)">
                <font-awesome-icon icon="fas fa-lock"></font-awesome-icon>
                <span class="align-middle ml-50">Reset Password</span>
              </b-dropdown-item>
              <b-dropdown-item v-if="data.item.state.status === 'FORCE_CHANGE_PASSWORD'" @click="resendUserInvitation(data.item)"> <!-- && -->
                <font-awesome-icon icon="fas fa-lock"></font-awesome-icon>
                <span class="align-middle ml-50">Resend Invitation</span>
              </b-dropdown-item>
              <b-dropdown-item v-if="!data.item.state.verified && data.item.state.status === 'CONFIRMED'" @click="verifyUserEmail(data.item)">
                <feather-icon icon="CheckCircleIcon" />
                <span class="align-middle ml-50">Verify Email</span>
              </b-dropdown-item>
              <b-dropdown-item @click="confirmUser(data.item)"> <!-- v-if="data.item.state.status === 'RESET_REQUIRED'" -->
                <feather-icon icon="CheckCircleIcon" />
                <span class="align-middle ml-50">Confirm User</span>
              </b-dropdown-item>
            </template>

            <b-dropdown-divider/>
            <b-dropdown-item class="table-row-option-enable"
                             @click="updateUserState(data.item)">
              <font-awesome-icon :icon="['fas', data.item.state.enabled ? 'toggle-off' : 'toggle-on']"></font-awesome-icon>
              <span class="align-middle ml-50">{{ data.item.state.enabled ? 'Disable' : 'Enable' }}</span>
            </b-dropdown-item>
            <can do="delete" on="management-users">
              <b-dropdown-item class="table-row-option-delete"
                               @click="$refs.layout.confirmDelete(data.item, deleteUser, cascadeConfirmDeleteOptions)">
                <feather-icon icon="TrashIcon" />
                <span class="align-middle ml-50">Delete</span>
              </b-dropdown-item>
            </can>
          </template>
        </table-layout>
    </template>

    <template #debug>
      <b-row>
        <b-col cols="6">
          <debug :collapsed="true">{{ options }}</debug>
        </b-col>
        <b-col cols="6">
          <debug :collapsed="true">{{ table }}</debug>
        </b-col>
      </b-row>
    </template>
  </page-layout>
</template>

<script>
import PageLayout from '@/components/PageLayout.vue';
import TableLayout from '@/components/TableLayout.vue';
import OverlayLoading from '@/components/OverlayLoading.vue';
import UserModal from '@/views/management/users/UserModal.vue'
import avatar from '@/mixins/avatar.mixin';
import notify from '@/mixins/notify.mixin';
import print from '@/mixins/print.mixin';
import vSelect from 'vue-select'
import { API, graphqlOperation } from 'aws-amplify';
import {listUsers, listUserSchools, updateUser, onCreateUser, onUpdateUser, onDeleteUser} from '@/graphql/queries/users';

import {
  adminGetUser,
  adminListGroups,
  adminListGroupsForUser,
  adminListUsersInGroup,
  adminEnableUser,
  adminDisableUser,
  adminResetUserPassword,
  adminResendUserInvitation,
  adminUpdateUserAttributes,
  adminConfirmSignUp,
  adminListUsers
} from '@/scripts/aws'
import { cascadeDeleteUser, cascadeConfirmDeleteOptions} from '@/graphql/cascade/user';
import {listSchools} from '@/views/all-state/application/queries/applications';

export default {
  name: 'Users',
  components: {
    OverlayLoading,
    PageLayout,
    TableLayout,
    UserModal,
    vSelect
  },
  mixins: [ avatar, notify, print ],
  data() {
    return {
      table: {
        items: [],
        loading: true,
        fields: [
          {
            key: 'name',
            subKeys: ['name.full', 'username', '@username'],
            label: 'Name',
            sortable: true,
            filterable: true,
            visible: true,
            tdClass: 'align-middle'
          },
          {
            key: 'email',
            label: 'Email',
            sortable: true,
            filterable: true,
            visible: true,
            tdClass: 'align-middle'
          },
          {
            key: 'schools.items',
            subKeys: ['schools.items.school.name.legal', 'schools.items.school.name.popular'],
            label: 'Schools',
            sortable: true,
            filterable: true,
            visible: false,
            tdClass: 'align-middle',
            isQueryField: true,
            hasQueryLoaded: false,
          },
          {
            key: 'groups',
            label: 'Groups',
            sortable: true,
            filterable: true,
            visible: true,
            tdClass: 'align-middle'
          },
          {
            key: 'state.enabled',
            label: 'Enabled',
            sortable: true,
            filterable: true,
            visible: true,
            tdClass: 'align-middle'
          },
          {
            key: 'state.createdAt',
            label: 'Created',
            sortable: true,
            filterable: true,
            visible: false,
            tdClass: 'align-middle'
          },
          {
            key: 'state.status',
            label: 'Status',
            sortable: true,
            filterable: true,
            visible: false,
            tdClass: 'align-middle'
          },
          {
            key: 'row-options',
            label: '',
            sortable: false,
            filterable: false,
            visible: true,
            tdClass: ['align-middle', 'table-row-options']
          },
        ],
        filters: {
          groups: { key: 'groups', value: null },
          enabled: { key: 'state.enabled', value: null }
        },
        sorting: {
          by: 'name.full',
          desc: false
        },
        subscriptions: {
          onCreate: null,
          onUpdate: null,
          onDelete: null
        }
      },
      options: {
        groups: {
          items: [],
          loading: true
        },
        /*cognitoUsers: {
          items: [],
          loading: true
        },
        amplifyUsers: {
          items: [],
          loading: true
        },
        cognitoGroups: {
          items: [],
          loading: true
        }*/
      },
      icon: 'fas fa-user',
      debug: { },
      cascadeConfirmDeleteOptions
    }
  },
  computed: {
    filterGroupOptions() {
      return this.options.groups.items.map(group => group.GroupName)
    },
    filterEnabledOptions() {
      return [{ label: 'true', value: true }, { label: 'false', value: 'false' }]
    },
    usernames() {
      if(this.table.loading) return []
      return this.table.items.map(item => item.username)
    },
    schoolsField() {
      return this.table.fields.find(field => field.key === 'schools.items')
    },
  },
  async created() {
    await this.listUsers();
    this.onCreateUser();
    this.onUpdateUser()
    this.onDeleteUser();
  },
  mounted() {
    this.$refs.layout.state.loading = false
  },
  methods: {
    async refresh() {
      this.table.loading = true
      await this.listUsers()
    },

    buildUser(user, schools, cognitoUser, cognitoGroups) {
      const groups = cognitoGroups.map(group => group.GroupName)
      return {
        id: user.id,
        username: user.username,
        email: cognitoUser.UserAttributes.find(attribute => attribute.Name === 'email').Value,
        name: {
          first: user.name?.first,
          last: user.name?.last,
          full: `${user.name?.first} ${user.name?.last}`
        },
        avatar: user.avatar,
        schools: {
          items: schools,
        },
        /*group: groups[0],*/
        groups: groups,
        state: {
          enabled: cognitoUser.Enabled,
          verified: cognitoUser?.Attributes?.find(attribute => attribute.Name === 'email_verified')?.Value === 'true' || false,
          status: cognitoUser.UserStatus,
          createdAt: user.createdAt
        }
      }
    },

    async listUsers_orig() {
      console.time('total')
      console.time('getData')
      this.table.loading = true
      this.options.cognitoUsers.loading = true
      this.options.amplifyUsers.loading = true
      this.options.cognitoGroups.loading = true

      /** Get Users from Amplify **/
      console.time('listAmplifyUsers')
      this.options.amplifyUsers.items = await this.listAmplifyUsers()
      this.options.amplifyUsers.loading = false
      console.timeEnd('listAmplifyUsers')

      /** Get Groups from Cognito **/
      console.time('adminListGroups')
      const cognitoGroupsResponse = await adminListGroups()
      this.options.cognitoGroups.items = cognitoGroupsResponse.Groups
      this.options.cognitoGroups.loading = false
      console.timeEnd('adminListGroups')

      this.options.groups.items = cognitoGroupsResponse.Groups.map(group => group.GroupName)
      this.options.groups.loading = false

      /** For Each Cognito Group, Get Users from Cognito Group **/
      const promises = this.options.cognitoGroups.items.map(async group => {
        console.time(`adminListUsersInGroup-${group.GroupName}`, group.GroupName)
        const groupUsers = await adminListUsersInGroup(group.GroupName)
        console.timeEnd(`adminListUsersInGroup-${group.GroupName}`, group.GroupName)
        return { name: group.GroupName, users: groupUsers.map(user => ({ ...user, id: user.Attributes.find(attribute => attribute.Name === 'custom:user_id').Value })) }
      })
      const groupUsers = await Promise.all(promises)
      console.timeEnd('getData')

      console.time('processData')
      /** Set Cognito Users from Cognito Group Users **/
      const cognitoUsers = []
      groupUsers.forEach(group => { group.users.forEach(user => cognitoUsers.push(user)) })
      this.options.cognitoUsers.items = [...new Map(cognitoUsers.map(item => [item.id, item])).values()]
      this.options.cognitoUsers.loading = false

      /** Build Users for Table **/
      const users = []
      this.options.amplifyUsers.items.forEach(amplifyUser => {
        //Find matching cognito user
        const cognitoUser = this.options.cognitoUsers.items.find(user => user.Attributes.some(attribute => attribute.Value === amplifyUser.id))

        //Find all groups that contain the user
        const groups = []
        groupUsers.forEach(group => {
          if(group.users.some(user => user.id === amplifyUser.id)) {
            groups.push(group.name)
          }
        })

        //Build user object
        const user = {
          id: amplifyUser.id,
          username: amplifyUser.username,
          email: cognitoUser?.Attributes?.find(attribute => attribute.Name === 'email').Value,
          name: {
            first: amplifyUser.name?.first,
            last: amplifyUser.name?.last,
            full: `${amplifyUser.name?.first} ${amplifyUser.name?.last}`
          },
          avatar: amplifyUser.avatar,
          address: amplifyUser.address,
          schools: {
            items: amplifyUser.schools?.items?.length ? amplifyUser.schools.items : []
          },
          groups: groups,
          state: {
            enabled: cognitoUser?.Enabled || false,
            status: cognitoUser?.UserStatus || 'Non-Cognito',
            verified: cognitoUser?.Attributes?.find(attribute => attribute.Name === 'email_verified')?.Value === 'true' || false,
            createdAt: amplifyUser.createdAt
          }
        }
        users.push(user)
      })

      /** Clean Up **/
      this.options.cognitoUsers.items = []
      this.options.amplifyUsers.items = []
      this.options.cognitoGroups.items = []

      /** Populate Table **/
      this.table.items = users;
      this.table.loading = false
      console.timeEnd('processData')
      console.timeEnd('total')
    },

    async listUsers() {
      this.table.loading = true
      this.options.groups.loading = true

      const [amplifyUsers, cognitoGroupsResponse] = await Promise.all([
        this.listAmplifyUsers(),
        adminListGroups()
      ]);

      const cognitoGroups = cognitoGroupsResponse.Groups;
      this.options.groups.items = cognitoGroupsResponse.Groups.map(group => group.GroupName)
      this.options.groups.loading = false

      const cognitoUsersMap = new Map();
      const groupUsersMap = await this.fetchGroupUsers(cognitoGroups);
      groupUsersMap.forEach(group => {
        group.users.forEach(user => {
          cognitoUsersMap.set(user.id, user);
        });
      });
      this.table.items = amplifyUsers.map(amplifyUser => this.buildUserObject(amplifyUser, cognitoUsersMap, groupUsersMap));
      this.table.loading = false
    },

    async fetchGroupUsers(cognitoGroups) {
      const groupPromises = cognitoGroups.map(async group => {
        const groupUsers = await adminListUsersInGroup(group.GroupName);
        return {
          name: group.GroupName,
          users: groupUsers.map(user => ({
            ...user,
            id: user.Attributes.find(attribute => attribute.Name === 'custom:user_id').Value
          }))
        };
      });
      return new Map((await Promise.all(groupPromises)).map(group => [group.name, group]));
    },
    buildUserObject(amplifyUser, cognitoUsersMap, groupUsersMap) {
      const cognitoUser = cognitoUsersMap.get(amplifyUser.id);
      const groups = Array.from(groupUsersMap.values()).filter(group => group.users.some(user => user.id === amplifyUser.id)).map(group => group.name);

      return {
        id: amplifyUser.id,
        username: amplifyUser.username,
        '@username': `@${amplifyUser.username}`,
        email: cognitoUser?.Attributes?.find(attribute => attribute.Name === 'email')?.Value,
        name: {
          first: amplifyUser.name?.first,
          last: amplifyUser.name?.last,
          full: `${amplifyUser.name?.first} ${amplifyUser.name?.last}`
        },
        avatar: amplifyUser.avatar,
        address: amplifyUser.address,
        schools: {
          items: amplifyUser.schools?.items?.length ? amplifyUser.schools.items : []
        },
        groups: groups,
        state: {
          enabled: cognitoUser?.Enabled || false,
          status: cognitoUser?.UserStatus || 'Non-Cognito',
          verified: cognitoUser?.Attributes?.find(attribute => attribute.Name === 'email_verified')?.Value === 'true' || false,
          createdAt: amplifyUser.createdAt
        }
      };
    },


    // eslint-disable-next-line consistent-return
    async listAmplifyUsers(nextToken, pagedUsers) {
      const users = pagedUsers || []

      const input = {
        limit: 1000,
        nextToken: nextToken,
        includeSchools: this.schoolsField ? this.schoolsField?.visible : false
      }

      const response = await API.graphql(graphqlOperation(listUsers, input));
      users.push(...response.data.listUsers.items);

      if(response.data.listUsers.nextToken) {
        await this.listAmplifyUsers(response.data.listUsers.nextToken, users)
      }
      return users
    },


    async addUser(user) {
      this.table.loading = true

      /** Get User from Cognito **/
      const cognitoUser = await adminGetUser(user.username)

      /** Get Groups from Cognito **/
      const cognitoGroupsResponse = await adminListGroupsForUser(user.username)
      const cognitoGroups = cognitoGroupsResponse.Groups

      const schoolsResponse = await API.graphql(graphqlOperation(listUserSchools));
      const schools = schoolsResponse.data.listUserSchools.items

      /** Build Table User **/
      const tableUser = this.buildUser(user, schools, cognitoUser, cognitoGroups)

      /** Add New Table User **/
      //Because addUser and onCreateUser both require external data it's a race to the finish to see who gets added
      if(!this.table.items.map(item => item.id).includes(user.id)) {
        this.table.items.push(tableUser)
      }
      this.table.loading = false
    },

    async updateUserState(user) {
      if(user.state.enabled) {
        await this.disableUser(user)
        await this.triggerOnUpdateUser(user)
      }
      else {
        await this.enableUser(user)
        await this.triggerOnUpdateUser(user)
      }
    },
    async enableUser(user) {
      await adminEnableUser(user.username).then(() => {
        user.state.enabled = true
        this.notify({ title: 'Success', text: 'User was successfully enabled', icon: this.icon, variant: 'success' });
      })
      .catch((e) => {
        console.error(e)
        this.notify({ title: 'Error', text: 'User failed to update', icon: this.icon, variant: 'danger' });
      })
    },
    async disableUser(user) {
      await adminDisableUser(user.username).then(() => {
        user.state.enabled = false
        this.notify({ title: 'Success', text: 'User was successfully disabled', icon: this.icon, variant: 'success' });
      })
      .catch((e) => {
        console.error(e)
        this.notify({ title: 'Error', text: 'User failed to update', icon: this.icon, variant: 'danger' });
      })
    },
    async resetUserPassword(user) {
      await adminResetUserPassword(user.username).then(() => {
        this.notify({ title: 'Success', text: 'User Password was successfully reset', icon: this.icon, variant: 'success' });
      })
      .catch((e) => {
        console.error(e)
        this.notify({ title: 'Error', text: 'Failed to reset user password', icon: this.icon, variant: 'danger' });
      })
    },
    async resendUserInvitation(user) {
      await adminResendUserInvitation(user.username).then(() => {
        this.notify({ title: 'Success', text: 'User Invitation was successfully reset', icon: this.icon, variant: 'success' });
      })
      .catch((e) => {
        console.error(e)
        this.notify({ title: 'Error', text: 'Failed to resend user invitation', icon: this.icon, variant: 'danger' });
      })
    },
    async verifyUserEmail(user) {
      await adminUpdateUserAttributes(user.username, [{ Name: 'email_verified', Value: 'true' }]).then((r) => {
        this.user.state.email_verified = true
        this.notify({ title: 'Success', text: 'User Email was verified reset', icon: this.icon, variant: 'success' });
      })
      .catch((e) => {
        console.error(e)
        this.notify({ title: 'Error', text: 'Failed to resend user invitation', icon: this.icon, variant: 'danger' });
      })
    },
    async confirmUser(user) {
      await adminConfirmSignUp(user.username, [{ Name: 'email_verified', Value: 'true' }]).then((r) => {
        this.notify({ title: 'Success', text: 'User Confirmed', icon: this.icon, variant: 'success' });
      })
      .catch((e) => {
        console.error(e)
        this.notify({ title: 'Error', text: 'Failed to confirm user.', icon: this.icon, variant: 'danger' });
      })
    },

    async deleteUser(user, swalCallback) {
      try {
        this.table.loading = true
        await this.cascadeDeleteUser(user.id, swalCallback)
        this.table.items = this.table.items.filter(item => item.id !== user.id);
        this.notify({ title: 'Success', text: 'User was successfully deleted', icon: this.icon, variant: 'success' });
      }
      catch(error) {
        console.error(error)
        this.notify({ title: 'Error', text: 'User failed to delete', icon: this.icon, variant: 'danger'});
        throw error //for Swal
      }
      finally {
        this.table.loading = false
      }
    },
    cascadeDeleteUser,

    onCreateUser() {
      this.table.subscriptions.onCreate = API.graphql(graphqlOperation(onCreateUser)).subscribe(async (sourceData) => {
        this.syncNotification()
        const user = sourceData.value.data.onCreateUser
        if (user && !this.table.items.map(item => item.id).includes(user.id)) {
          this.table.loading = true
          /** Get User from Cognito **/
          const cognitoUser = await adminGetUser(user.username)

          /** Get Groups from Cognito **/
          const cognitoGroupsResponse = await adminListGroupsForUser(user.username)
          const cognitoGroups = cognitoGroupsResponse.Groups

          const schoolsResponse = await API.graphql(graphqlOperation(listUserSchools));
          const schools = schoolsResponse.data.listUserSchools.items

          /** Build Table User **/
          const tableUser = this.buildUser(user, schools, cognitoUser, cognitoGroups)

          /** Add New Table User **/
          //Because addUser and onCreateUser both require external data it's a race to the finish to see who gets added
          if(!this.table.items.map(item => item.id).includes(user.id)) {
            this.table.items = [tableUser, ...this.table.items];
          }
          this.table.loading = false
        }
      });
    },
    onUpdateUser() {
      this.table.subscriptions.onUpdate = API.graphql(graphqlOperation(onUpdateUser)).subscribe(async (sourceData) => {
        this.syncNotification()
        const user = sourceData.value.data.onUpdateUser
        if (user && this.table.items.map(item => item.id).includes(user.id)) {
          this.table.loading = true

          /** Get User from Cognito **/
          const cognitoUser = await adminGetUser(user.username)

          /** Get Groups from Cognito **/
          const cognitoGroupsResponse = await adminListGroupsForUser(user.username)
          const cognitoGroups = cognitoGroupsResponse.Groups

          const schoolsResponse = await API.graphql(graphqlOperation(listUserSchools));
          const schools = schoolsResponse.data.listUserSchools.items

          /** Build Table User **/
          const tableUser = this.buildUser(user, schools, cognitoUser, cognitoGroups)

          const index = this.table.items.findIndex(item => item.id === user.id)
          if(index > -1) {
            this.table.items.splice(index, 1, tableUser)
          }
          this.table.loading = false
        }
      });
    },
    onDeleteUser() {
      this.table.subscriptions.onDelete = API.graphql(graphqlOperation(onDeleteUser)).subscribe((sourceData) => {
        this.syncNotification()
        const user = sourceData.value.data.onDeleteUser
        if(user && this.table.items.map(item => item.id).includes(user.id)) {
          this.table.loading = true
          this.table.items = this.table.items.filter(item => item.id !== user.id);
          this.table.loading = false
        }
      });
    },

    async triggerOnUpdateUser(user) {
      try {
        await API.graphql(graphqlOperation(updateUser, { input: { id: user.id } }));
        this.notify({ title: 'Success', text: 'User was successfully updated', icon: this.icon, variant: 'success' });
      }
      catch (err) {
        console.error(err)
        this.notify({ title: 'Error', text: 'User failed to update', icon: this.icon, variant: 'danger' });
      }
    },

    groupVariant(group) {
      let variant
      switch(group.toLowerCase()) {
        case 'admin':
        case 'nyssma_admin':
          variant = 'danger'
          break;
        case 'nyssma_staff':
          variant = 'primary'
          break;
        case 'teacher':
          variant = 'secondary'
          break;
        default:
          variant = 'dark'
      }
      return `light-${variant}`
    },
    statusVariant(status) {
      let variant
      switch(status.toLowerCase()) {
        case 'confirmed':
          variant = 'success'
          break;
        case 'force_change_password':
          variant = 'primary'
          break;
        default:
          variant = 'dark'
      }
      return `light-${variant}`
    }
  }
}
</script>

<style lang="scss">
.group-badge {
  margin-right: .5em;
}
</style>
